import React, { useContext } from 'react';
import { View, Text, Linking, Pressable, StyleSheet } from 'react-native';
import publicIP from 'react-native-public-ip';

import api from '../api/client';

import { AuthContext } from './Context';

export default function Ad({id, title, url}) {

    const { user } = useContext(AuthContext);

    const pID = user? user.personID : null;

    const handleHit = async () => {
        Linking.openURL(url);

        publicIP()
            .then(ip => {
                api.put('/Ads/' + id + '/' + pID + '/' + ip);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getIPV4Address = async() => {
        const wifiIP = await RNNetworkInfo.getWIFIIPV4Address();
        console.log("wifiIP: " + wifiIP);

        if (wifiIP && wifiIP !== '0.0.0.0') {
          return wifiIP;
        }
        
        return await RNNetworkInfo.getIPV4Address();
    }

    return (
        <Pressable onPress={() => handleHit()}>
            <View style={styles.container}>
                {/* <Image style={styles.image} source={ require('../assets/images/shop/' + id + '/1.jpg') } /> */}
                <Text style={styles.title}>{title}</Text>
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        margin: 15,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
    },
    title: {
        fontSize: 15,
    },
    image: {
        width: 150,
        height: 150
    }
})