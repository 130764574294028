import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, TextInput, Pressable, ScrollView, StyleSheet } from 'react-native';
import MyButton from './form/MyButton';
import NoRight from './NoRight';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function Email() {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Customer Service'))
        return <NoRight />;

    const [data, setData] = useState([]);   //messages
    const [eID, setEID] = useState(0);
    const [message, setMessage] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (eID > 0)
            fetchMessage();
    }, [eID]);

    const fetchData = async () => {
        const { data } = await api.get('/Email/Messages');
        setData(data);
        setEID(data[0].eid);
    }

    const fetchMessage = async () => {
        const { data } = await api.get('/Email/Message/' + eID);
        setMessage(data);
    }

    const handleText = (text) => {
        setText(text);
    }

    const send = async () => {
        let v = new FormData();
        v.append("PreID", eID);
        v.append("SenderID", admin.pid);
        v.append("Name", "");
        v.append("SenderEmail", "");
        v.append("Message", text);
        v.append("IsRead", 0);
      //  v.append("SendTime", Date.now());

        await api.post('/Chat/', v);
    }

    const handlePress = async (eid) => {
        setEID(eid);
        await api.put('/Email/ReadMessage/' + eid);
    }

    const renderItem = ({ item }) => (
        <Pressable onPress={() => handlePress(item.eid)}>
            <View style={[styles.messBox, { backgroundColor: item.isRead? '#d4e6f1': '#ebdef0' }]}>
                <Text style={styles.name}>{item.name}</Text>
                <Text style={styles.message}>{item.message}</Text>
            </View>
        </Pressable>
    );

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <ScrollView>
                    <FlatList
                        data={data}
                        keyExtractor={item => item.eid}
                        renderItem={renderItem}
                        numColumns={1}
                    ></FlatList>
                </ScrollView>
            </View>
            <View style={styles.right}>
                <View style={[styles.box, { marginBottom: 5 }]}>
                    <TextInput
                        editable={false}
                        multiline
                        numberOfLines={10}
                        value={message}
                        style={styles.textInput}
                    />
                </View>
                <View style={styles.box}>
                <TextInput
                        editable={true}
                        multiline
                        numberOfLines={15}
                        value={text}
                        onChangeText={text => handleText(text)}
                        style={styles.textInput}
                    />
                    <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <MyButton text='Send' color='black' width={150} onPress={() => send()} />
                    </View>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#EBF5FB',
    },
    right:{
        marginLeft: 5,
        width: '80%'
    },
    menu: {
        flex: 1,
        flexDirection: 'column',
        marginBottom: 5
    },
    main: {
        flex: 8,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5
    },
    box: {
        width: '48%',
        justifyContent: 'flex-start',
        alignSelf: 'center',
    },
    menuBtn: {
        marginLeft: 10
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 25,
        marginVertical: 10
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 40,
    },
    a: {
        fontWeight: '300',
        color: '#FF3366', // make links coloured pink
    },
    box: {
        width: '95%'
    },
    textInput: {
        padding: 5,
        borderWidth: 2,
        fontSize: 18,
        width: '100%',
        borderRadius: 2,
        borderColor: 'dodgerblue'
    },
    messBox: {
        width: 300,
        padding: 3,
        marginVertical: 3,
        backgroundColor: '#eaf2f8'
    },
    name: {
        fontFamily: 'Calibri',
        fontSize: 18,
    },
    message: {
        fontFamily: 'Calibri',
        fontSize: 16,
        color: 'darkgray'
    },
})