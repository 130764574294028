import React, { useState, useEffect } from 'react';
import { View, Text, Button, FlatList, ScrollView, Pressable, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import Newsletter from './Newsletter';
import Pagination from './Pagination';
import RolePanel from './RolePanel';
import LoginPanel from './LoginPanel';
import Popup from './Popup';
import NoRight from './NoRight';

import api from '../api/client';
import useAuth from '../hooks/useAuth';

export default function Users({  }) {
    const { user:admin } = useAuth();
    const roles = admin.roles;

    if (!roles.includes('Super User'))
        return <NoRight />;

    const [users, setUsers] = useState([]);

    const [showForm, setShowForm] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRoles, setShowRoles] = useState(false);

    const pageSize = 20;
    const [pageID, setPageID] = useState(1);
    const [total, setTotal] = useState(20);

    const [userID, setUserID] = useState(0);  //selected user ID

    useEffect(() => {
        fetchCount();
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [pageID]);

    const fetchCount = async () => {
        const { data } = await api.get('/User/Total');
        setTotal(data);
    }

    const fetchUsers = async () => {
        const { data } = await api.get('/User/' + pageID + '/' + pageSize);
        setUsers(data);
    }

    const openLogin = async (pid) => {
        setUserID(pid);
        setShowLogin(true);
    }

    const openRole = (pid) => {
        setUserID(pid);
        setShowRoles(true);
    }

    const openDelete = (pid) => {
        setUserID(pid);
        setShowDelete(true);
    }

    const deleteUser = async () => {
        const { data } = await api.put('/User/Delete/' + userID);

        setShowDelete(false);

        if (data == true)
            fetchData();
    }

    const renderHeader = () => (
        <View style={[styles.row, {backgroundColor: '#D6EAF8'}]}>
            <View style={styles.vID}>
                <Text style={styles.text}>ID</Text>
            </View>
            <View style={styles.vName}>
                <Text style={styles.text}>Name</Text>
            </View>
            <View style={styles.vEmail}>
                <Text style={styles.text}>Email</Text>
            </View>
            <View style={styles.vDate}>
                <Text style={styles.text}>Created Date</Text>
            </View>
            <View style={styles.vDate}>
                <Text style={styles.text}>Expire</Text>
            </View>
            <View style={styles.vDate}>
                <Text style={styles.text}>Login</Text>
            </View>
            <View style={styles.vMoney}>
                <Text style={styles.text}>Paid</Text>
            </View>
            <View style={styles.vRoles}>
                <Text style={styles.text}>Roles</Text>
            </View>
            <View style={styles.vBtn}>
                <Text style={styles.text}>Delete</Text>
            </View>
        </View>
    );

    const renderItem = ({item}) => {
        const { pid, firstName, lastName, email, createdDate, memberToDate, isMember, coupon, credit, amount, lastLogin } = item;
        const color = pid === userID? '#EBDEF0':'white';
        return (
        <Pressable style={[styles.row, {backgroundColor: color}]} onPress={() => setUserID(pid)}>
            <View style={styles.vID}>
                <Text>{pid}</Text>
            </View>
            <View style={styles.vName}>
                <Text>{firstName + " " + lastName}</Text>
            </View>
            <View style={styles.vEmail}>
                <Pressable onPress={() => setShowForm(true)}>
                    <Text>{email}</Text>
                </Pressable>
                <Popup show={showForm} onClose={() => setShowForm(false)}>
                    <Newsletter onSave={() => handleSend()} />
                </Popup>
            </View>
            <View style={styles.vDate}>
                <Text>{createdDate}</Text>
            </View>
            <View style={styles.vDate}>
                <Text>{memberToDate}</Text>
            </View>
            <View style={styles.vDate}>
                <Button title={lastLogin} onPress={() => openLogin(pid)} />
            </View>
            <View style={styles.vMoney}>
                {amount > 0? <Text>${amount}</Text> : null}
            </View>
            <View style={styles.vRoles}>
                {roles.includes('Super User')?
                <Pressable onPress={() => openRole(pid)}>
                    <MaterialCommunityIcons name='account' color='black' size={24} />
                </Pressable> : null}
            </View>
            <View style={styles.vBtn}>
                {roles.includes('Super User')?
                <Pressable onPress={() => openDelete(pid)}>
                    <MaterialCommunityIcons name='delete' color='black' size={24} />
                </Pressable> : null}
            </View>
        </Pressable>);
    }

    return (
        <View style={styles.container}>
            {users.length > 1 && <Pagination total={total} pageSize={pageSize} pageID={pageID} onChange={item => setPageID(item)} />}
            <ScrollView>
                {renderHeader()}
                <FlatList
                    data={users}
                    numColumns={1}
                    keyExtractor={item => item.pid}
                    renderItem={renderItem}
                    style={styles.list}
                ></FlatList>
            </ScrollView>
            <Popup show={showLogin} onClose={() => setShowLogin(false)}>
                <View style={{padding: 20}}>
                    <LoginPanel personID={userID} />
                </View>
            </Popup>
            <Popup show={showRoles} onClose={() => setShowRoles(false)}>
                <View style={{padding: 20}}>
                    <RolePanel userID={userID} adminID={admin.pid} />
                </View>
            </Popup>
            <Popup show={showDelete} onClose={() => setShowDelete(false)}>
                <View style={{padding: 20}}>
                    <Text style={styles.question}>Are you sure you want to delete this user?</Text>
                    <Button title="Delete" color="black" onPress={() => deleteUser()} />
                </View>
            </Popup>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '95%',
    },
    row: {
        width: '100%',
        height: 50,
        backgroundColor: 'white',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: 'lightgray',
    },
    vID: {
        width:'8%',
        alignItems: 'flex-start',
        paddingLeft: 20
    },
    vName: {
        width:'15%',
    },
    vEmail: {
        width:'25%',
    },
    vDate: {
        width:'8%',
        alignItems: 'center',
    },
    vMoney: {
        width:'10%',
        alignItems: 'center',
    },
    vRoles: {
        width:'10%',
        alignItems: 'center',
    },
    vBtn: {
        width:'5%',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Candara',
        fontSize: 16
    },
    question: {
        fontFamily: 'Candara',
        fontSize: 24,
        marginBottom: 20
    },
})