import React from 'react';
import { View, Modal, Pressable, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function Popup({ children, show, onClose }) {
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={show}
            onRequestClose={() => onClose()}
        >
            <View style={styles.m1}>
                <View style={styles.m2}>
                    <Pressable style={styles.vClose} onPress={() => onClose()}>
                        <Ionicons name='close-outline' color='black' size={45} />
                    </Pressable>
                    {children}
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    m1: {
        width: '80%',
        justifyContent: "center",
        alignSelf: 'center',
        alignItems: "center",
    },
    m2: {
        width: '80%',
        height: '80%',
        backgroundColor: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 40,
        paddingVertical: 30,
        margin: 20,
        borderRadius: 10,
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.4)',
    },
    vClose: {
        width: 60,
        height: 60,
        borderRadius: 30,
        backgroundColor: "#D0ECE7",
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: -30
    },
})