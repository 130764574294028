import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import api from '../api/client';

export default function LoginPanel({ personID }) {

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/User/LoginHistory/' + personID);
        setData(data);
    }
    
    const renderItem = ({item}) => {
        const { ip, time } = item;
        return (
            <View style={styles.box}>
                <View style={{width: 200, alignItems: 'flex-start'}}>
                    <Text style={styles.text}>{ip}</Text>
                </View>
                <View style={{width: 380, alignItems: 'flex-start'}}>
                    <Text style={styles.text}>{time}</Text>
                </View>
            </View>
            );
    }

    return (
        <View style={styles.container}>
            <FlatList
                data={data}
                numColumns={1}
                keyExtractor={item => item.id}
                renderItem={renderItem}
            ></FlatList>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 600,
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 10
    },
    box: {
        width: 800, flex: 1, flexDirection: 'row', borderWidth: 1, borderColor: 'black', padding: 10
    },
    text: {
        fontSize: 18
    }
})