import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Pressable, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MyButton from './form/MyButton';

import api from '../api/client';

export default function TempEditor({ tempID }) {

    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        getSubject();
        getTemplate();
    }, [tempID]);

    const getSubject = async () => {
        const { data } = await api.get('/Email/Subject/' + tempID);
        setSubject(data);
    }

    const saveSubject = async () => {
        let s = encodeURIComponent(subject.trim());
        await api.put('/Email/Subject/' + tempID + '/' + s);
    }

    const getTemplate = () => {
        let file = '/email/template'+ tempID +'.txt';
        let client = new XMLHttpRequest();
        client.open('GET', file);
        client.onreadystatechange = function() {
            setText(client.responseText);
        }
        client.send();
    }

    const saveTemplate = async () => {
        let v = new FormData();
        v.append("ID", tempID);
        v.append("TempName", "N");
        v.append("Text", text);

        const { data } = await api.post('/Email/Template/', v);
        if (data == 1)
            setMsg('Saved');
    }

    const send = async (email, name) => {
        await api.put('/Email/' + tempID + '/' + email + '/' + name);
    }

    return (
        <View style={styles.container}>
            <View style={{flexDirection: 'row'}}>
                <View style={{width: 100}}>
                    <Text style={{fontFamily: 'Calibri', fontSize: 18}}>Subject</Text>
                </View>
                <View style={styles.textLine}>
                    <TextInput style={{width:'100%', height:'100%', fontSize: 18}}
                        value={subject}
                        editable={true}
                        onChangeText={t => setSubject(t)}
                        autoCompleteType="off"
                    />
                </View>
                <Pressable style={styles.saveBtn} onPress={() => saveSubject()}>
                    <MaterialCommunityIcons name="content-save" color="black" size={30} />
                </Pressable>
            </View>
            
            <TextInput
                value={text}
                editable={true}
                multiline
                numberOfLines={25}
                onChangeText={t => setText(t)}
                onFocus={() => setShowHTML(true)}
                style={{padding: 5, borderWidth: 2, fontSize: 18, width: '100%', borderRadius: 2, borderColor: 'dodgerblue'}}
            />

            <View style={{flex: 1, flexDirection: 'row'}}>
                <View style={styles.btns}>
                    <MyButton text='Save' color='black' width={150} onPress={() => saveTemplate()} />
                </View>
                <View style={styles.btns}>
                    <MyButton text='Send' color='limegreen' width={150} onPress={() => send()} />
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '90%',
        backgroundColor: '#EBF5FB',
        padding: 10,
    },
    main: {
        height: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: 5
    },
    footer: {
        height: 80,
        flexDirection: 'row'
    },
    btns: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%'
    },
    box: {
        width: '48%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'center',
    },
    menuBtn: {
        marginLeft: 10
    },
    english: {
        fontFamily: 'Candara',
        fontSize: 25,
        marginVertical: 10
    },
    title: {
        fontFamily: 'Candara',
        fontSize: 40,
    },
    text: {
        fontFamily: 'Calibri',
        fontSize: 18,
        fontWeight: 'bold'
    },
    textLine: {
        width: 600,
        flexDirection: 'row',
        backgroundColor: '#D0ECE7',
        borderColor: 'black',
        borderBottomWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 15,
        height: 40
    },
    saveBtn: {
        backgroundColor: '#A3E4D7',
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        marginLeft: 10
    },
    body: {
        fontSize: 18
    },
    a: {
        fontWeight: '300',
        color: '#FF3366', // make links coloured pink
    },
})